import React from "react"
import SocialMediaLinks from "./socialMediaLinks"

export const Footer = () => (
  <section className="footer-section-wrapper bg-lighter-beige">
    <div className="section footer-section">
      <div>
        <p className="footer-name">Ségolène Alquier</p>
        <p>Thank you for your time!</p>
      </div>
      <div>
        <div className="social-media-container">
          <SocialMediaLinks />
        </div>
        <span className="copyright">
          <span role="img" aria-label="emoji sparkle">
            ✨
          </span>{" "}
          © {new Date().getFullYear()}, Built with{" "}
          <a href="https://www.gatsbyjs.org">Gatsby</a> and{" "}
          <a href="https://www.netlifycms.org/">Netlify CMS</a>{" "}
          <span role="img" aria-label="emoji sparkle">
            ✨
          </span>
        </span>
      </div>
    </div>
  </section>
)
