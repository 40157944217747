import React from "react"
import Sidebar from "../components/Sidebar"

const Name = () => (
  <div className="navbar-name-wrapper">
    <img className="navbar-picture" src="/profile-picture.jpg" alt="profile avatar"></img>
    <a href="/" className="navbar-name">
      Ségolène Alquier
    </a>
  </div>
)
const NavLinks = () => (
  <>
    <div className="navbar-links">
      <a className="navbar-link" href="/">
        Home
      </a>
      <a className="navbar-link" href="/blog">
        Blog
      </a>
      <a className="navbar-link" href="/about">
        About me
      </a>
    </div>
  </>
)

const Navbar = () => (
  <div className="navbar-wrapper">
    <div className="navbar">
      <Name />
      <NavLinks />
    </div>
    <Sidebar />
  </div>
)

export default Navbar
