import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faDev,
  faGithub,
  faTwitter,
  faCodepen,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons"

const SocialMediaLinks = () => (
  <div className="social-media-wrapper">
    <a href={`https://twitter.com/SegoleneAlquier`}>
      <FontAwesomeIcon icon={faTwitter} className="social-media-icon" title='Twitter profile link'/>
    </a>
    <a href={`https://github.com/Segolene-Alquier`}>
      <FontAwesomeIcon icon={faGithub} className="social-media-icon" title='Github profile link'/>
    </a>
    <a href={`https://dev.to/segolenealquier`}>
      <FontAwesomeIcon icon={faDev} className="social-media-icon" title='Dev.to profile link'/>
    </a>
    <a href={`https://codepen.io/segolene-alquier`}>
      <FontAwesomeIcon icon={faCodepen} className="social-media-icon" title='Codepen profile link'/>
    </a>
    <a href={`https://www.linkedin.com/in/segolene-alquier/`}>
      <FontAwesomeIcon icon={faLinkedin} className="social-media-icon" title='Linkedin profile link'/>
    </a>
  </div>
)

export default SocialMediaLinks
